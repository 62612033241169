import Dexie from 'dexie';
import { Position } from '../common/types';
import { Event as ApiEvent, Place as ApiPlace } from '@eventer/api-client';


export interface Place extends ApiPlace {
  _updatedAt: number;
}

export interface Event extends ApiEvent {
  _updatedAtTimestamp: number;
  _startsAtTimestamp: number;
  _location: Position;
}

export interface UpdateIdx {
  lat, lon, day: number;
  updated_since: number;
}

class EventerDb extends Dexie {

  events: Dexie.Table<Event, string>;
  places: Dexie.Table<Place, string>;
  updateIdx: Dexie.Table<UpdateIdx, [number, number, number]>;


  constructor () {
    super('eventer');
    this.version(12).stores({
      events: 'id,_startsAtTimestamp',
      places: 'google_place_id',
      updateIdx: '[lat+lon+day],day'
    });
  }

  async initialize(): Promise<void> {
    console.log('initialize eventerDb');

    let recreate = false;
    try {
      await this.open();
      console.log('database version: ', this.verno);
      if (this.verno < 12) {
        recreate = true;
      }
    } catch (err) {
      console.log('error on opening database', err);
      recreate = true;
    }
    if (recreate) {
      try {
        this.close();
      } catch (err) { }
      Dexie.delete('eventer');
      await this.open();
    }

    return Promise.resolve(undefined);
  }


  async getPlacesByIds(placeIds: string[]): Promise<Map<String, Place>> {
    const map = new Map<String, Place>();
    if (placeIds && placeIds.length > 0) {
      await eventerDb.places
        .where("google_place_id").anyOf(placeIds)
        .each(place => { map[place.google_place_id] = place });
    }
    return map;
  }

  async deleteEvents(eventIds: string[]): Promise<void> {
    if (eventIds && eventIds.length > 0) {
      console.log(`delete ${eventIds.length} event(s) from database`);
      return eventerDb.events.bulkDelete(eventIds);
    }
  }
}

export const eventerDb = new EventerDb();
