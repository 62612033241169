import { eventerDb, Place as DbPlace, UpdateIdx, Event as DbEvent } from './eventer/eventer-db';

import { eventerApiClient, SearchEventsRequest, Place as ApiPlace } from '@eventer/api-client';
import { getDateByDayIdx, getDayIdxByDate, getDistance, parseTimestamp } from './eventer-util';

interface RasterPoint {
  latIdx, lonIdx: number;
  key: string;
  requestValue: [number, number];
}

class Graph {
  rasterPoints: RasterPoint[] = [];
  dayIdxMin: number;
  dayIdxMax: number;
  updatedSince: number;
}

export default () => {

  const _eventerSyncer: any = {};

  /**
   * Fetches specified places and stores them in the database with 'now' as _updatedAt timestamp.
   *
   * @param {Array.<String>} placeIds An array of placeIds to fetch from api and store in database.
   * @returns {Promise} A promise with fetched places as map (placeId => place).
   */
  const _fetchPlacesAndStoreInDb = async (placeIds) => {

    const map: { [k:string]: DbPlace } = {};
    if (placeIds == null || placeIds.length == 0) {
      return map
    }

    const now: number = Date.now();
    const placesResponse = await eventerApiClient.getPlaces(placeIds)

    // We have to filter out all errors, which will be ignored by now.
    const apiPlaces = placesResponse.filter((entry) => "google_place_id" in entry ) as ApiPlace[];
    const dbPlaces: DbPlace[] = apiPlaces.map((apiPlace) => {
      const dbPlace: DbPlace = {
        ...apiPlace,
        _updatedAt: now,
      };
      map[dbPlace.google_place_id] = dbPlace;
      return dbPlace;
    });

    await eventerDb.places.bulkPut(dbPlaces);
    console.log(`stored ${dbPlaces.length} places to local db: `, placeIds);

    return map;
  }

  /**
   * Syncs the specified places if needed.
   *
   * @param {Array.<String>} placeIds An array of placeIds to sync.
   * @returns {Promise} A promise with specified places as map (placeId => place).
   */
  const _syncPlaces = async (placeIds: string[]) => {
    // Info: consider processing this logic "synchronized" to avoid parallel requests to same places,
    // but the underlying dataloader (eventer api) is caching and combining request to same places.
    if (!placeIds || placeIds.length == -1) {
      return {};
    }
    const placesInDb = await eventerDb.getPlacesByIds(placeIds);
    // fetch all places which are not in database or "outdated" (older than 15 days)
    const minUpdatedAt = Date.now() - (1000 * 60 * 60 * 24 * 15);
    const placeIdsToInsert: string[] = [];
    const placeIdsToUpdate: string[] = [];
    placeIds.forEach(placeId => {
      if (placesInDb[placeId] == undefined) {
        placeIdsToInsert.push(placeId);
      } else if (placesInDb[placeId]._updatedAt < minUpdatedAt) {
        placeIdsToUpdate.push(placeId);
      }
    });
    // fetch and store all places to insert and wait for
    const fetchedPlaces = await _fetchPlacesAndStoreInDb(placeIdsToInsert);
    // trigger "fetch-and-update" places in parallel, must not chained
    // Info: may dataloader is combining these requests again (to reduce api calls),
    // but this should be ok for performance reasons, important is not to chain
    // this update if there are only place updates (and no inserts).
    _fetchPlacesAndStoreInDb(placeIdsToUpdate);
    return Object.assign({}, placesInDb, fetchedPlaces);
  };

  /**
   * Performs a event search and stores them in local database and starts a sync of all related places.
   *
   * @param {Object} params The search params used for api call.
   * @returns {Promise} A Promise with the event search response.
   */
  const _searchEventsAndStoreInDb = async (params: SearchEventsRequest) => {

    const response = await eventerApiClient.searchEvents(params);
    if (!response.events || response.events.length == 0) {
      return response;
    }

    console.log(`store ${response.events.length} events to local db`);

    // first sync places (we enhance all events with location for fast access)
    const placeIds: string[] = response.events.map(e => e.google_place_id).filter(e => e != null) as string[]
    const places = await _syncPlaces(placeIds);

    // enhance events and store them in local database
    const dbEventsToUpdate: DbEvent[] = [];
    const eventIdsToDelete: string[] = [];

    // 'eventFlux' needs joined places for each event (same result expected like 'loadEvents')
    // TODO: hotfix, we need an own ResponseType for this function
    //       (maybe we should extend official reponse with places and use this functionality)
    let responseEvents: any[] = [];
    response.events.forEach(event => {

      let googlePlaceId = event.google_place_id
      if (!googlePlaceId) {
        // events without google place informations will be ignored
        return
      }

      const dbEvent = <DbEvent>{
        ...event,
        _startsAtTimestamp: parseTimestamp(event.starts_at),
        _updatedAtTimestamp: parseTimestamp(event.updated_at),
        _location: places[googlePlaceId].location,
      };

      if (event.deleted_at) {
        eventIdsToDelete.push(event.id);
      } else {
        dbEventsToUpdate.push(dbEvent);
      }

      responseEvents.push({
        ...dbEvent,
        _place: places[googlePlaceId],
      });
    });

    if (dbEventsToUpdate.length > 0) {
      await eventerDb.events.bulkPut(dbEventsToUpdate);
    }

    if (eventIdsToDelete.length > 0) {
      await eventerDb.deleteEvents(eventIdsToDelete);
    }

    return { ...response, events: responseEvents };
  };

  /**
   * Performs a event search for all events (using cursor iteration until there are no more events
   * or limits are reached) and stores them in local database and starts a sync of all related places.
   *
   * @param {Object} params The (base) search params used for the api calls.
   * @param {Object} limits Some limits when the recursion should stop.
   * @param {number} limits.limit1 If reached for all following requests 'starts_at_max' will be patched to end of actual day (default: 1000).
   * @param {number} limits.limit2 If reached the iteration will break (default: 2000).
   * @param {function} eventFlux (optional) A callback to 'flux' all received events.
   * @returns {Promise} A Promise with the following structure as result:
   *    @param {Object} info Containing some infos of the executed requests.
   *    @param {number} info.count The count of fetched events.
   *    @param {boolean} info.reachedLimit1 True, if given limit1 has been reached.
   *    @param {boolean} info.reachedLimit1 True, if given limit2 has been reached.
   *    @param {Object} lastResponse The (original) last response.
   */
  const _searchAllEventsAndSyncRecursively = async (params: SearchEventsRequest, limits, eventFlux, info) => {
    const _params = Object.assign({ limit: 100 }, params);
    const _limits = Object.assign({ limit1: 1000, limit2: 2000 }, limits);
    // TODO: is there a better approach for variables for a recursively method call?
    let _info = Object.assign({ count: 0 }, info);

    const response = await _searchEventsAndStoreInDb(_params);
    if (response && response.events) {
      _info.count += response.events.length;
      if (eventFlux && response.events.length > 0) {
        eventFlux.call(this, response.events, response.timestamp);
      }
    }
    if (response.cursor && response.events && response.events.length > 0) {
      // we have to iterate => one more request
      if (_info.count > _limits.limit1 && !_info.reachedLimit1) {
        // we reached sync limit 1 => restrict next requests to events of current day
        _info.reachedLimit1 = true;
        const newStartsAtMax = new Date(Date.parse(response.events[response.events.length - 1].starts_at));
        newStartsAtMax.setDate(newStartsAtMax.getDate() + 1);
        newStartsAtMax.setHours(0, 0, 0, 0);
        if (!_params.starts_at_max || newStartsAtMax.getTime() < Date.parse(_params.starts_at_max)) {
          _params.starts_at_max = newStartsAtMax.toISOString();
          console.log("reached sync limit-1 (" + _limits.limit1 + "), patch 'starts_at_max' to end of day: " + _params.starts_at_max);
        } else {
          console.log("reached sync limit-1 (" + _limits.limit1 + ")");
        }
      }
      if (_info.count > _limits.limit2) {
        console.log("reached hard sync limit-2 (" + _limits.limit2 + ") => break iteration");
        _info.reachedLimit2 = true;
        return Promise.resolve({ info: _info, lastResponse: response });
      }
      _params.cursor = response.cursor;
      // TODO: implement as loop instead of recursive pattern
      return _searchAllEventsAndSyncRecursively(_params, _limits, eventFlux, _info);
    } else {
      return { info: _info, lastResponse: response };
    }
  };



  const _setUpdatedSince = (rasterPoints, dayIdxMin: number, dayIdxMax: number, updatedSince: number) => {
    console.log("_setUpdatedSince: ", dayIdxMin, dayIdxMax);
    const dbEntries: UpdateIdx[] = [];
    for (let dayIdx = dayIdxMin; dayIdx <= dayIdxMax; dayIdx++) {
      rasterPoints.forEach((rp) => {
        dbEntries.push({ lat: rp.latIdx, lon: rp.lonIdx, day: dayIdx, updated_since: updatedSince });
      });
    }

    console.log("=== _syncAllEventsByRaster.dbEntries ===");
    console.log(dbEntries);

    return eventerDb.updateIdx.bulkPut(dbEntries);
  };


  const _syncAllEventsByRaster = (params: SearchEventsRequest, rasterPoints, dayIdxMin, dayIdxMax, eventFlux) => {
    if (!rasterPoints || rasterPoints.length == 0) {
      throw "no param 'rasterPoints' given";
    }
    if (!dayIdxMin) {
      throw "no param 'dayIdxMin' given";
    }
    if (!dayIdxMax) {
      throw "no param 'dayIdxMax' given";
    }

    const request: SearchEventsRequest = {
      ...params,
      geo_raster_points: rasterPoints.map(rp => [parseInt(rp.latIdx) / 10, parseInt(rp.lonIdx) / 10]),
      starts_at_min: getDateByDayIdx(dayIdxMin).toISOString(),
      starts_at_max: getDateByDayIdx(dayIdxMax + 1).toISOString(),
    };

    console.log("=== _syncAllEventsByRaster.request ===");
    console.log(request);

    const promises: Promise<any>[] = [];

    let dayIdx: number = dayIdxMin;
    const _eventFlux = (events, timestamp) => {

      console.log("got fluxed events: ", events);
      const _dayIdxMax: number = events.reduce(
        (dayIdx, event) => Math.max(dayIdx, getDayIdxByDate(event.starts_at)), dayIdx);
      console.log("_dayIdxMax: " + _dayIdxMax);

      if (_dayIdxMax > dayIdx) {
        promises.push(_setUpdatedSince(rasterPoints, dayIdx, (_dayIdxMax - 1), parseTimestamp(timestamp)));
      }
      dayIdx = _dayIdxMax;

      if (eventFlux) {
        eventFlux.call(this, events, timestamp);
      }
    };

    return _searchAllEventsAndSyncRecursively(request, null, _eventFlux, null)
      // update updatedSince for last response
      .then(({lastResponse}) => {
        console.log("==> ", dayIdx);
        return _setUpdatedSince(rasterPoints, dayIdx, dayIdxMax, parseTimestamp(lastResponse.timestamp));
      })
      // wait for all collected database updates promises
      .then(Promise.all(promises))
  };


  /**
   * Syncs all events of a day (with related places) for the specified geo-area.
   *
   * @param {*} geoArea The geo-area to sync {top, left, bottom, right}.
   * @returns {Promise} If sync has been completed successfully.
   */
  const _syncEvents = (geoArea, fromDate, toDate, timestamp, eventFlux) => {

    const syncTimestamp = timestamp || new Date().getTime();

    let dateMin = fromDate || new Date();
    let dayIdxMin = getDayIdxByDate(dateMin);
    let dateMax;
    if (toDate) {
      dateMax = toDate;
    } else {
      dateMax = dateMin;
      dateMax.setMonth(dateMax.getMonth() + 1);
    }
    let dayIdxMax = getDayIdxByDate(dateMax);
    let latMin = Math.round(geoArea.bottom * 10);
    let latMax = Math.round(geoArea.top * 10);
    let lonMin = Math.round(geoArea.left * 10);
    let lonMax = Math.round(geoArea.right * 10);

    console.log("sync events", `lat: [${latMin}, ${latMax}], lon: [${lonMin}, ${lonMax}], dayIdx: [${dayIdxMin}, ${dayIdxMax}]`);

    let latIdx, lonIdx: number;
    const allRasterPoints: RasterPoint[] = [];
    for (latIdx = latMin; latIdx <= latMax; latIdx++) {
      for (lonIdx = lonMin; lonIdx <= lonMax; lonIdx++) {
        allRasterPoints.push(_buildRasterPoint(latIdx, lonIdx));
      }
    }

    console.log("=== allRasterPoints ===");
    console.log(allRasterPoints);

    return eventerDb.updateIdx
      .where("[lat+lon+day]").between([latMin, lonMin, dayIdxMin], [latMax, lonMax, (dayIdxMax + 1)])
      .toArray(rows => {
        return rows.reduce((result, row) => {
          const _rp = _buildRasterPoint(row.lat, row.lon);
          result[row.day] = result[row.day] || {};
          result[row.day][_rp.key] = { rasterPoint: _rp, row: row };
          return result;
        }, {});
      })
      .then(rows => {

        console.log("=== rows ===");
        console.log(rows);

        const fetchGraphs: Graph[] = [];
        const rasterPointsToIgnore = {};

        let hasMore = true;
        while (hasMore) {

        // build initial graph
        const graph = new Graph();
        for (let dayIdx = dayIdxMin; dayIdx <= dayIdxMax; dayIdx++) {

          graph.dayIdxMin = dayIdx;

          const row = rows[dayIdx];
          if (row) {

            // there are entries for this day => find all relevant ones

            // holds all raster points which have no entry for this day yet
            // (=> most important entries at first)
            const missingRasterPoints: RasterPoint[] = [];

            let minUpdatedSince = Number.MAX_VALUE;
            allRasterPoints.forEach(rp => {

              if (rasterPointsToIgnore[dayIdx] && rasterPointsToIgnore[dayIdx][rp.key]) {
                // this raster point have to be ignored
                return;
              }

              if (row[rp.key]) {
                // there exists an entry
                if (row[rp.key].row.updated_since < syncTimestamp) {
                  // a relevant candidate
                  minUpdatedSince = Math.min(minUpdatedSince, row[rp.key].row.updated_since);
                }
              } else {
                // no entry in database yet => preferred entry
                missingRasterPoints.push(rp);
              }
            });

            if (missingRasterPoints.length > 0) {
              graph.rasterPoints = missingRasterPoints;
            } else if (minUpdatedSince !== Number.MAX_VALUE) {
              graph.updatedSince = minUpdatedSince;
              // update-threshold of 3 hours
              const maxUpdatedSince = minUpdatedSince + (1000 * 60 * 60 * 3);
              allRasterPoints.forEach(rp => {
                if (row[rp.key]
                    && row[rp.key].row.updated_since < syncTimestamp
                    && row[rp.key].row.updated_since >= minUpdatedSince
                    && row[rp.key].row.updated_since <= maxUpdatedSince) {
                      graph.rasterPoints.push(rp);
                }
              });
            }

          } else {

            // the whole day is not in database (for this raster points)
            // => all relevant raster points of this day will be the starting graph
            graph.rasterPoints = allRasterPoints.slice(0);

            if (rasterPointsToIgnore && rasterPointsToIgnore[dayIdx]) {
              graph.rasterPoints = graph.rasterPoints.filter(rp => {
                return !rasterPointsToIgnore[dayIdx] || !rasterPointsToIgnore[dayIdx][rp.key];
              });
            }
          }

          if (graph.rasterPoints.length > 0) {
            break;
          }
        }

        // expand

        // update-threshold of 3 hours (if updatedSince exists)
        const maxUpdatedSince = graph.updatedSince ? graph.updatedSince + (1000 * 60 * 60 * 3) : -1;

        graph.dayIdxMax = graph.dayIdxMin;
        for (let dayIdx = graph.dayIdxMin + 1; dayIdx <= dayIdxMax; dayIdx++) {

          const row = rows[dayIdx];
          if (row) {

            // all rasterPoints must be in valid updatedSince range
            const breakFlag = graph.rasterPoints.reduce((_breakFlag, rp) => {
              if (_breakFlag) {
                // short cut
                return true;
              }
              if (row[rp.key]) {
                  if (graph.updatedSince) {
                    if (row[rp.key].row.updated_since >= graph.updatedSince
                          && row[rp.key].row.updated_since <= maxUpdatedSince) {
                      // valid
                      return false;
                    }
                  }
                  return true;
              } else {
                // no rasterPoint found for this day
                if (graph.updatedSince) {
                  // but an updatedSince is given => we can not expand anymore
                  return true;
                }
              }
              return false;
            }, false);
            if (breakFlag) {
              break;
            }
          } else {
            // no entries for this day
            if (graph.updatedSince) {
              // graph to expand has an updatedSince => we can not expand anymore
              break;
            }
          }
          graph.dayIdxMax = dayIdx;
        }


        console.log("=== graph ===");
        console.log(graph);

        if (graph && graph.rasterPoints && graph.rasterPoints.length > 0) {
          fetchGraphs.push(graph);
          for (let dayIdx = graph.dayIdxMin; dayIdx <= graph.dayIdxMax; dayIdx++) {
            rasterPointsToIgnore[dayIdx] = rasterPointsToIgnore[dayIdx] || [];
            graph.rasterPoints.forEach(rp => rasterPointsToIgnore[dayIdx][rp.key] = true);
          }
        } else {
          hasMore = false;
        }
      }

      console.log("=== fetchGraphs ===");
      console.log(fetchGraphs);

      let promises = fetchGraphs.map(graph => {
        const params: SearchEventsRequest = {};
        if (graph.updatedSince) {
          params.updated_since = new Date(graph.updatedSince).toISOString();
          params.include = { deleted: true};
        }
        return _syncAllEventsByRaster(params, graph.rasterPoints, graph.dayIdxMin, graph.dayIdxMax, eventFlux);
      });

      return Promise.all(promises);
    });
  };


  function _buildRasterPoint(latIdx: number, lonIdx: number): RasterPoint {
    return {
      latIdx: latIdx,
      lonIdx: lonIdx,
      key: latIdx + "," + lonIdx,
      requestValue: [
        latIdx / 10,
        lonIdx / 10]
    };
  };


  /* public methods */

  _eventerSyncer.initialize = async () => {
    console.log('initialize eventer-syncer');
    await eventerDb.initialize();
  };

  _eventerSyncer.loadEvents = async (params) => {
    if (!params || !params.starts_at || !params.limit || !params.geolocation) {
      throw "invalid params";
    }

    console.log("load events: ", params);
    let count = 0;
    const events:any = await eventerDb.events
      .where("_startsAtTimestamp").aboveOrEqual(parseTimestamp(params.starts_at))
      .filter((event) => {
        const distance = getDistance(params.geolocation.position, event._location);
        return distance <= params.geolocation.radius;
      })
      .until((event) => {
        return ++count >= params.limit;
      }, true)
      .toArray();

    const placeIds = {};
    events.forEach((event) => placeIds[event.google_place_id] = {});

    const places = await eventerDb.getPlacesByIds(Object.keys(placeIds));
    events.forEach((event) => event._place = places[event.google_place_id]);

    return events;
  };

  _eventerSyncer.syncEvents = (params, eventFlux) => {
    if (!params.starts_at_min) {
      throw "starts_at_min missing";
    }
    if (!params.starts_at_max) {
      throw "starts_at_max missing";
    }
    const startsAtMin = parseTimestamp(params.starts_at_min);
    const startsAtMax = parseTimestamp(params.starts_at_max);
    if (startsAtMax < startsAtMin) {
      throw "invalid 'starts_at'-range";
    }
    /*if (startsAtMin.diff(startsAtMax, 'days') > 10) {
      throw "sync period must be not greater than 10 days";
    }*/
    if (!params.geoArea) {
      throw "param 'geoArea' missing";
    }

    return _syncEvents(params.geoArea, params.starts_at_min, params.starts_at_max, params.syncTimestamp, eventFlux)
      // hide internal results (= promises), we only return null
      .then(() => null);
  };

  _eventerSyncer.searchEvents = (params) => {
    return _searchEventsAndStoreInDb(params)
      .then(response => response.events);
  };

  _eventerSyncer.cleanUp = () => {
    const timestamp = Date.now() - (1000 * 60 * 60 * 24 * 5); // 5 days ago
    console.log("clean up old database entries before: ", new Date(timestamp));
    // clean up events
    return eventerDb.events
      .where('_startsAtTimestamp').below(timestamp)
      .delete()
      .then(() => {
        // clean up updateIdx
        return eventerDb.updateIdx
          .where('day').below(getDayIdxByDate(timestamp))
          .delete();
      });
  };

  return _eventerSyncer;
};
