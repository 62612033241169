'use strict';

import EventerSyncer from './eventer-syncer';


const _eventerSyncer = new EventerSyncer();

const _respondSuccess = ({ type, id }, payload, completed) => {
  postMessage({ type, id, payload, completed });
};

const _respondError = ({ type, id }, error, completed) => {
  console.log("[eventer-worker] error: ", error);
  postMessage({ type, id, error: error.message, completed });
};

onmessage = (e) => {

  const msg = e.data;
  console.log("[eventer-worker] got message: ", msg);

  try {

    let flux = null;
    if (msg.flux) {
      flux = chunk => _respondSuccess(msg, chunk);
    }

    _eventerSyncer[msg.type](msg.payload, flux)
      .then(result => _respondSuccess(msg, result, true))
      .catch(error => _respondError(msg, error, true));

  } catch (error) {
    _respondError(msg, error, true);
  }
};
